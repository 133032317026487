.resultButtonsDiv {
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: transparent;
  border: 3px solid #ff625f;
  color: #ff625f;
  padding: 30px 20px;
  width: 47%;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.obs1{
  font-size: 14px;
  color: #ff625f;
}
doc {
  width: 50px;
}

@media (max-width: 1000px) {
  .resultButtonsDiv {
    flex-direction: column;
  }

  .button {
    width: 100%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 18px;
  }

  .currentLegalBasisDescription,
  p {
    font-size: 14px;
  }
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  body {
    width: 245mm !important;
    height: 100%;
    margin: auto !important;
    padding: 0 !important;
    line-height: 1.3em !important;
  }

  #headerPrint {
    /* position: fixed; */
    /* top: 15pt !important; */
    margin-top: 0%;
  }

  #pdf {
    margin-top: 20pt;
  }

  #footerPrint {
    /* position: fixed; */
    /* bottom: 10pt !important; */
    margin-bottom: 0%;
    text-align: justify;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-right: 0%;
    width: 98.5%;
  }

  .content-block,
  p {
    page-break-inside: avoid !important;
  }

  .print {
    display: block !important;
  }

  .noPrint {
    display: none !important;
  }
}

.print {
  display: none;
}

@page {
  size: A4;
  margin: 0.5cm !important;
}

.wholeBody {
  padding: 0px 80px 30px 80px;
}

.homeLink {
  height: 130px;
  font-size: 24px;
  background-color: transparent;
}


@media (max-width: 1000px) {
  .wholeBody {
    padding: 0px 0px 0px 0px;
  }

  .homeLink {
    height: 75px;
    padding-left: 30px;
  }
  p{
    font-size: 10px;
  }
}

input[type='radio']:checked + label,
input[type='checkbox']:checked + label {
  background-color: #ff625f !important;
  border: 3px solid #ff625f !important;
  color: white;
}

input[type='radio'] + label,
input[type='checkbox'] + label {
  border: 3px solid #ff625f !important;
}

input::placeholder {
  color: #ccd4cc;
}

.question{
  margin: 10px;
  padding: 40px 20px;
  font-size: 24px;
  color: white;
  background-color: #2e382e;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.obs{
  font-size: 15px;
}

.option {
  padding: 20px;
  width: 100%;
  font-size: 21px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .question {
    font-size: 20px;
  }

  .option {
    font-size: 15px !important;
  }

  .button {
    font-size: 15px !important;
  }
}
